import { isFinite } from "lodash-es";
import useSWR from "swr";

import keepPreviousData from "@Helpers/keepPreviousData/keepPreviousData";
import type { Products } from "@Collections/products";
import { API_URLS } from "@Collections/routes";
import { fetcher } from "@DataAccess/local";
import type { FinancePlan } from "@Components/Checkout/stages/components/PaymentMethods/Finance/types";
import useHasFinance from "@Hooks/useHasFinance";

type Props = {
  productType: Products | undefined;
  selectedPlanId?: string;
  deposit?: number;
  purchasePrice: number | undefined;
  isBnpl?: boolean;
  isPayMonthly?: boolean;
};

type ReturnType = {
  isLoading: boolean;
  isValidating: boolean;
  financePlans: FinancePlan[];
  hasError: Boolean;
};

const useFetchFinancePlans = ({
  deposit,
  isBnpl = true,
  isPayMonthly = true,
  productType,
  purchasePrice,
}: Props): ReturnType => {
  const { hasFinance } = useHasFinance({ productType });

  const depositAmount = isFinite(deposit) ? deposit : Math.floor(Number(purchasePrice) / 2);

  const { data, error, isValidating } = useSWR<FinancePlan[]>(
    hasFinance && isFinite(depositAmount) && isFinite(purchasePrice)
      ? [API_URLS.FINANCE.PLANS, { deposit: depositAmount, isBnpl, isPayMonthly, productType, purchasePrice }]
      : null,
    ([url, params]) => fetcher(url, params),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      use: [keepPreviousData],
    },
  );

  return {
    financePlans: data || [],
    hasError: Boolean(error),
    isLoading: hasFinance && !data && !error,
    isValidating,
  };
};

export default useFetchFinancePlans;
