import Image from "@Components/Image";

import { IconContainer } from "./styles";

type Props = {
  type: string;
};

const Emoji = ({ type }: Props) => {
  return (
    <IconContainer>
      <Image alt="" height={32} src={`/_next-public/images/emoji/${type}.png`} width={32} />
    </IconContainer>
  );
};

export default Emoji;
