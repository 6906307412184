import Link from "next/link";
import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";
import { Button, Grid, H3, H5, Paragraph, Spacing } from "@boxt/design-system";

import currencyFormatter from "@Helpers/currency";
import { CUSTOMER_URLS } from "@Collections/routes";
import type { FinancePlan } from "@Components/Checkout/stages/components/PaymentMethods/Finance/types";
import { FinancePaymentTypes } from "@Components/FinanceCalculator/types";
import { StyledHr } from "@Components/layout";
import type { CostCalulator } from "@Components/pages/boilers/subscription/HeadlineCard/components/types";
import type { ButterFinanceCalculatorTabFields, LifeRepExample } from "@Components/pages/finance/types";

import PaymentDetail from "./PaymentDetail";
import {
  ButtonWrapper,
  ContentWrapper,
  PaymentDetailsContainer,
  RightColumnTitleDiv,
  SummaryContainer,
} from "./styles";

export type Props = {
  activePlan: CostCalulator | FinancePlan | LifeRepExample;
  content: ButterFinanceCalculatorTabFields;
  hasFixedAmount: boolean;
  onAddToBasket?: () => void;
  onProductDetailsClick?: () => void;
  isPdpRedirectEnabled: boolean;
  showAddToBasketCta: boolean;
  ctaText?: string;
  paymentType: FinancePaymentTypes;
};

const PaymentDetails = ({
  content,
  activePlan,
  hasFixedAmount,
  onAddToBasket,
  onProductDetailsClick,
  isPdpRedirectEnabled,
  showAddToBasketCta,
  ctaText,
  paymentType,
}: Props) => {
  const { t } = useTranslation("common");

  const {
    representative_title: title,
    calc_tab_cta_button_link: ctaButtonLink,
    calc_tab_cta_button_text: ctaButtonText,
    calc_tab_cta_button_theme: ctaButtonTheme,
    representative_description: representativeDescription,
  } = content;

  const {
    calculations: {
      monthlyPayment,
      deposit: depositAmount,
      creditBorrowed,
      totalRepayableAmount,
      totalInterestPaid,
      priceOfGoods,
      servicingCosts,
    },
    apr: interestRate,
    repApr,
    term: agreementDurationMonths,
    deferredPeriod,
  } = activePlan;

  const details = [
    { key: "monthly-payments", value: currencyFormatter(monthlyPayment) },
    { key: "credit-amount", value: currencyFormatter(creditBorrowed) },
    { key: "effective-apr", testId: "finance-percent", value: `${interestRate}%` },
    { key: "rate-of-interest", value: t("calculator.interest-value", { interest: `${interestRate}%` }) },
    { key: "cost-of-finance", value: currencyFormatter(totalInterestPaid) },
    {
      key: "total-repayable",
      tooltipText: t("calculator.tooltips.total-repayable"),
      value: currencyFormatter(totalRepayableAmount),
    },
    { key: "advance-payment", value: currencyFormatter(depositAmount) },
    {
      key: "total-term",
      value: t("calculator.months", { amount: Number(agreementDurationMonths) + Number(deferredPeriod) }),
    },
    { key: "deferral-period", value: t("calculator.months", { amount: deferredPeriod }) },
    { key: "payment-term", value: t("calculator.months", { amount: agreementDurationMonths }) },
    { key: "price-of-goods", value: currencyFormatter(priceOfGoods) },
    { key: "servicing-cost", value: currencyFormatter(servicingCosts) },
    { key: "representative-apr", value: `${repApr}%` },
  ];

  const leftKeyMapper = {
    bnpl: ["advance-payment", "credit-amount", "effective-apr", "rate-of-interest"],
    default: ["monthly-payments", "credit-amount", "effective-apr"],
    life: ["monthly-payments", "rate-of-interest", "price-of-goods", "servicing-cost"],
  };

  const rightKeyMapper = {
    bnpl: ["total-term", "deferral-period", "payment-term", "total-repayable"],
    default: ["rate-of-interest", "cost-of-finance", "total-repayable"],
    life: ["total-term", "representative-apr", "cost-of-finance", "total-repayable"],
  };

  return (
    <PaymentDetailsContainer>
      <ContentWrapper>
        <Grid.Row>
          <Grid.Col>
            <SummaryContainer>
              <Spacing mt={3}>
                <Paragraph align="center" boxtTheme="slate">
                  <Trans
                    components={{ strong: <strong /> }}
                    i18nKey={
                      paymentType === FinancePaymentTypes.BNPL
                        ? "common:calculator.deferred-monthly-payments-of"
                        : "common:calculator.monthly-payments-of"
                    }
                    values={{ deferralPeriod: deferredPeriod, months: agreementDurationMonths }}
                  />
                </Paragraph>
                <H3 align="center" boxtTheme="coalDark" weight="bold">
                  {currencyFormatter(monthlyPayment)}
                </H3>
              </Spacing>
            </SummaryContainer>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col>
            <Spacing mv={3}>
              <RightColumnTitleDiv>
                <H5 top={3} weight="regular">
                  {title || t("calculator.representative-example")}
                </H5>
              </RightColumnTitleDiv>
            </Spacing>
            <StyledHr />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col lg={8}>
            {leftKeyMapper[paymentType].map((detailKey) => {
              const detail = details.find((detail) => detail.key === detailKey);
              const key = detail?.key;
              const value = detail?.value;
              const testId = detail?.testId;
              const tooltipText = detail?.tooltipText;

              return (
                <PaymentDetail
                  key={key}
                  label={t(`calculator.${key}`)}
                  testId={testId}
                  tooltipText={tooltipText}
                  value={value}
                />
              );
            })}
          </Grid.Col>
          <Grid.Col>
            {rightKeyMapper[paymentType].map((detailKey) => {
              const detail = details.find((detail) => detail.key === detailKey);
              const key = detail?.key;
              const value = detail?.value;
              const testId = detail?.testId;
              const tooltipText = detail?.tooltipText;

              return (
                <PaymentDetail
                  key={key}
                  label={t(`calculator.${key}`)}
                  testId={testId}
                  tooltipText={tooltipText}
                  value={value}
                />
              );
            })}
          </Grid.Col>
        </Grid.Row>
        {paymentType === FinancePaymentTypes.BNPL ||
          (paymentType === FinancePaymentTypes.Life && (
            <Paragraph boxtTheme="slateLight" size="small" top={5} weight="regular">
              {representativeDescription || t("calculator.bnpl-help-text")}
            </Paragraph>
          ))}
        <ButtonWrapper>
          {hasFixedAmount ? (
            <>
              {showAddToBasketCta && (
                <Spacing mv={2}>
                  <Button
                    boxtTheme={paymentType === FinancePaymentTypes.Life ? "coral" : "jade"}
                    isFullWidth
                    onClick={onAddToBasket}
                    size="large"
                  >
                    {paymentType === FinancePaymentTypes.Life ? t("calculator.choose") : t("calculator.add-to-basket")}
                  </Button>
                </Spacing>
              )}
              {onProductDetailsClick && (
                <Button
                  boxtTheme={isPdpRedirectEnabled ? "jade" : "coal"}
                  isFullWidth
                  onClick={onProductDetailsClick}
                  size="large"
                  skin={isPdpRedirectEnabled ? "primary" : "secondary"}
                >
                  {ctaText}
                </Button>
              )}
            </>
          ) : (
            <Link href={ctaButtonLink || CUSTOMER_URLS.BOILER} legacyBehavior passHref>
              <Button boxtTheme={ctaButtonTheme?.theme || "jade"} isFullWidth size="large">
                {ctaButtonText || t("calculator.fixed-price")}
              </Button>
            </Link>
          )}
        </ButtonWrapper>
      </ContentWrapper>
    </PaymentDetailsContainer>
  );
};

export default PaymentDetails;
