import { useFsFlag } from "@flagship.io/react-sdk";

import type { Products } from "@Collections/products";
import useProductType from "@Hooks/useProductType";

type Props = {
  productType: Products | undefined;
};

type ReturnType = {
  hasFinance: Boolean;
};

const useHasFinance = ({ productType }: Props): ReturnType => {
  const isSolarFinanceAvailable = useFsFlag("is_solar_finance_available", false).getValue();
  const { isSolar, isBoilerRental } = useProductType({ productType });

  const hasFinance = () => {
    if (isSolar) {
      return isSolarFinanceAvailable;
    }

    return !isBoilerRental;
  };

  return {
    hasFinance: hasFinance(),
  };
};

export default useHasFinance;
